import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
// import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/adu1.png";
import myImg1 from "../../Assets/adu.png";
import Particle from "../Particle";

function Home2() {
  useEffect(() => {
    let timers = new Map(); // To store animation timers for each element
  
    const handleScroll = () => {
      const articles = document.querySelectorAll("article");
      const scrollPosition = window.scrollY + window.innerHeight;
  
      articles.forEach((article) => {
        const articlePosition = article.getBoundingClientRect().top + window.scrollY;
  
        if (scrollPosition > articlePosition) {
          // Trigger the animation after 1 second
          if (!article.classList.contains("scroll-in-view")) {
            const timer1 = setTimeout(() => {
              article.classList.add("scroll-in-view");
  
              // Remove animation class after 2 seconds
              const timer2 = setTimeout(() => {
                article.classList.remove("scroll-in-view");
              }, 2000); // 2 seconds after animation starts
              timers.set(article, timer2);
            }, 500); // Delay of 1 second
            timers.set(article, timer1);
          }
        } else {
          // Reset the element if it goes out of view
          const timer1 = timers.get(article);
          const timer2 = timers.get(article);
  
          if (timer1) clearTimeout(timer1);
          if (timer2) clearTimeout(timer2);
  
          article.classList.remove("scroll-in-view");
          timers.delete(article);
        }
      });
    };
  
    // Attach scroll listener
    window.addEventListener("scroll", handleScroll);
  
    // Cleanup listener and timers on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      timers.forEach((timer) => clearTimeout(timer)); // Clear timers
      timers.clear();
    };
  }, []);
  
  return (
    <Container fluid className="home-about-section" id="about">
      <Particle />
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with programming and I have at least learnt
              something, I think… 🤷‍♂️
              <br />
              <br />I am fluent in classics like
              <i>
                <b className="purple"> C, Html, CSS, Javascript, React Js, Node Js, Express Js, MongoDB & MySQL </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web App Development </b></i> and
              also in areas related to{" "}
              <i>
                <b className="purple">
                  {/* Deep Learning and Natural Launguage Processing. */}
                  Full Stack Webdevelopment.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> Node.js, React.js and Express.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <article>
              <img src={myImg} className="image1" alt="avatar-background" />
              <img src={myImg1} className="image2" alt="avatar-overlay" />
              <img src={myImg1} className="image3" alt="avatar-overlay1" />
            </article>
          </Col>

        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/amarupd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/amar_upd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/amar-upd/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/amar_upd/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
